@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'),
        url('./style-fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf')
            format('truetype');
}

@font-face {
    font-family: 'OpenSansBold';
    src: local('OpenSansBold'),
        url('./style-fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf')
            format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'OpenSansItalic';
    src: local('OpenSansItalic'),
        url('./style-fonts/Open_Sans/static/OpenSans/OpenSans-Italic.ttf')
            format('truetype');
}

@font-face {
    font-family: 'RobotoCondensed';
    src: local('RobotoCondensed'),
        url('./style-fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf')
            format('truetype');
}

@font-face {
    font-family: 'RobotoSlab';
    src: local('RobotoSlab'),
        url('./style-fonts/Roboto_Slab/static/RobotoSlab-Regular.ttf')
            format('truetype');
}

@font-face {
    font-family: 'RobotoSlabBold';
    src: local('RobotoSlabBold'),
        url('./style-fonts/Roboto_Slab/static/RobotoSlab-Bold.ttf')
            format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'RobotoSlabSemiBold';
    src: local('RobotoSlabSemiBold'),
        url('./style-fonts/Roboto_Slab/static/RobotoSlab-SemiBold.ttf')
            format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: local('OpenSansSemiBold'),
        url('./style-fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf')
            format('truetype');
}
* {
    margin: 0;
    padding: 0;
}
/* 
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
} */
