//*     === FONTS ===

$font-stack: "OpenSans","OpenSansSemiBold", "RobotoCondensed", "RobotoSlab", "RobotoSlabSemiBold";

//*     === COLORS === 

$primary-color:#f8f9fa; 
$secondary-color:#e9ecef;
$border-color:#dee2e6;
$minor-color:#ADB5BD;
$primary-font-color:#212529;
$secondary-font-color:#6c757d;
$header-font-color:#343A40;


//      =========================
//*     === TABLE OF CONTENTS ===
//      =========================

/*      1. Common Styles
        2. Common Text
        3. Global Styles
        4. Side Bar
        5. Body
        6. Content
        7. Edit Page
        8. Video Player
        9. Components
          - Form
          - Search Bar
          - Button
          - Spinner
*/ 


//      =====================
//*     === COMMON STYLES ===
//      =====================

$border: 1px solid $border-color;

%primary-font {
  font-family: "OpenSans";
  color: $primary-font-color
}

%secondary-font {
  font-family: "OpenSans";
  color: $secondary-font-color
}

%minor-font {
  font-family: "RobotoCondensed";
  color: $secondary-font-color
  
}
%header-font {
  font-family: "RobotoSlabSemiBold";
  color: $header-font-color;
  letter-spacing: 1px;
}

//      ===================
//*     === COMMON TEXT ===
//      ===================

.span-text {
  @extend %primary-font;
  display: block;

  &___light {
    @extend %secondary-font;
    letter-spacing: .5px;
  }
 
  &___bold {
    @extend %primary-font;
    font-weight:  bold;
    letter-spacing: 1px;
  }

  &___italic {
    font-style: italic;
  }
}

.span-header {
  @extend %header-font;
  display: block;

  &___capital {
    @extend %header-font;
    text-transform: capitalize;
    &___light {
      color: $secondary-font-color;
      @extend %header-font;
      text-transform: capitalize;
    }
  }

  &___light {
    color: $secondary-font-color;
  }

  &___bold {
    font-weight:  bold;
  }

  &___italic {
    font-style: italic;
  }
}

//      =====================
//*     === GLOBAL STYLES ===
//      =====================

header {
  border-bottom: $border;
  background-color: $primary-color;
  text-align: center;
  height: 5rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  &___date {
    @extend %secondary-font;
    position: absolute;
    left: 4rem;
    top: 1.75rem;
  }
}

footer {
  @extend %minor-font;
    border-top: $border;
    background-color: $primary-color;
    text-align: center;
    height: 3rem;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
}

//      ===============
//*     === SIDEBAR ===
//      ===============

$sidebar-bg-color: #E9ECEF;
$sidebar-color: #343A40;
$submenu-indent: 2rem;
$sidebar-width: 20rem;
// $submenu-bg-color: #CED4DA;
$submenu-bg-color-collapsed: #CED4DA;
$highlight-color: #0466C8;
$submenu-bg-color: #2b2b2b !default;
@import '~react-pro-sidebar/dist/scss/styles.scss';

.sidebar {
  display: flex;
  left: 0;
  // width: 16rem;
  top: 5rem;
  position: fixed;
  bottom: 3rem;

  &___content {
    @extend %primary-font;
    color: $secondary-font-color;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
  }

  &___header {
    @extend %header-font;
    width: 100%;
    margin-top: 0.5rem;
    font-weight: 450;
    display: flex;
    justify-content: center;
    font-size: larger;
  }

  &___footer {
    @extend %minor-font;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 300;
    font-size: small;
    display: flex;
    justify-content: center;
  }
}

//      ============
//*     === BODY ===
//      ============

.body {
  background: $secondary-color;
  position: fixed;
  left: 20rem;
  right: 0;
  bottom: 3rem;
  top: 5rem;
}

//      ===============
//*     === CONTENT ===
//      ===============

.content {
  // height:100%;
  border: $border;
  background-color: $primary-color;
  border-radius: 5px;
  position: fixed;
  top: 6rem;
  bottom: 4rem;
  left: 20rem;
  right: 1rem;
  padding: 1rem;

  &___title {
    @extend %header-font;
    text-transform: capitalize;
    text-align: center;
  }

  &___header {
    @extend %header-font;
    letter-spacing: 1px;
  }

  &___text {
    @extend %secondary-font;
    letter-spacing: .5px;
  }
}

//      =================
//*     === EDIT PAGE ===
//      =================

.edit {
  display: flex;
  flex-direction: column;
  position: relative;
  // bottom: 3rem;
  // padding: 1rem;

  &___header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 2rem 0 2rem;
    padding-left: 6rem;

    &___text {
      @extend %header-font;
      letter-spacing: 1px;
    }

    &___control {
      padding: .75rem;
      flex-direction: row;
      overflow: auto;
    }

    &___buttons {
      padding: 1rem
    }
  }

  &___drop-downs {
    display: flex;
    position: relative;
    margin-left: 0;
    margin-right: auto;
  }

  &___drop-down-item {
    letter-spacing: .5px;
    text-transform: capitalize;
  }

  &___text-wrap {
   padding: 1rem;
  }

  &___form {
    display: flex;
    position: relative;
    margin-left: 0;
    margin-right: auto;
  }
  &___item {
    // max-width: 40%;
    margin: 1rem;
  }
}

//      ====================
//*     === VIDEO PLAYER ===
//      ====================

.video-page {
  position: relative;

  &___header {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &___title {
    padding-left: 1rem;
    margin-right: 1rem;
    padding-bottom: .5rem;
    @extend %header-font;
    text-transform: capitalize;
    text-align: center;
  }
}

.player {
  justify-content: center;
  display: flex;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4.5rem;
}

//      ==================
//*     === COMPONENTS ===
//      ==================
   
//*     === FORM ===

.form {
  border: $border;
  background-color: $secondary-color;
  padding: 2rem;
  border-radius: 10px;
  position: fixed;
  left: 40%;
  right: 40%;
  width: 20%;
  min-width: 20rem;
  top: 3rem;
}

//*     === SEARCH BAR ===

.search-bar {
  position: fixed;
  left: 40%;
  right: 40%;
  width: 20%;
}

//*     === BUTTONS ===

.button-wrapper {
  @extend %secondary-font;
  display: flex;
  flex-direction: row-reverse;
  padding: 1rem;
  align-items: center;
  margin-top: 0.25rem;
}

.menu-button {
  position: sticky;
  border: medium none;
  max-height: 40px;
  min-height: 38.5px;
}

//*     === SPINNER ===

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
}

.date {
  position: absolute;
  left: 4rem;
  margin-bottom: 1rem;
}